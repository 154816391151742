<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="userData === undefined">
      <h4 class="alert-heading">
        {{ $t("message.errorLoadingData") }}
      </h4>
      <div class="alert-body">
        {{ $t("message.user.errorUser") }}
        <b-link class="alert-link" :to="{ name: 'apps-users-list' }">
          {{ $t("message.user.listOfUser") }}
        </b-link>
        {{ $t("message.user.forOtherUser") }}
      </div>
    </b-alert>

    <template v-if="userData">
      <div>
        <b-card>
          <h2 class="mt-2 mb-3">{{ $t("message.user.viewUser") }}</h2>
          <b-row>
            <b-col md="3">
              <b-form-group label="Permissão " label-for="systemRole">
                <template v-slot:label>
                  {{ $t("message.user.permission") }}
                </template>
                <v-select
                  v-model="userData.systemRole"
                  :reduce="(val) => val.value"
                  :options="typeOptions"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Grupo de acesso" label-for="userGroup">
                <template v-slot:label>
                  {{ $t("message.user.accessGroup") }}
                </template>

                <v-select
                  v-model="userData.userGroup"
                  :options="groupOptions"
                  :reduce="(val) => val.value"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group label-for="userGroup">
                <template v-slot:label>
                  {{ $t("sessionTime") }}
                </template>

                <b-form-input
                  v-model="userData.sessionTime"
                  type="number"
                  readonly
                />
              </b-form-group>
            </b-col>

            <b-col md="1">
              <b-form-group label="Active" label-for="userGroup">
                <template v-slot:label>
                  {{ $t("message.user.active") }}
                </template>
                <b-form-checkbox
                  style="margin-left: 7px; margin-top: 12px"
                  v-model="userData.enabled"
                  disabled
                >
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group label="Active" label-for="userGroup">
                <template v-slot:label>
                  {{ $t("message.list.activeTimeSheet") }}
                </template>
                <b-form-checkbox
                  style="margin-left: 7px; margin-top: 12px"
                  v-model="userData.timeSheet"
                  disabled
                >
                </b-form-checkbox>
              </b-form-group>
            </b-col>   
            
            <b-col md="2">
              <b-form-group label="Active" label-for="userGroup">
                <template v-slot:label>
                  Bater ponto
                </template>
                <b-form-checkbox
                  style="margin-left: 7px; margin-top: 12px"
                  v-model="userData.point"
                  disabled
                >
                </b-form-checkbox>
              </b-form-group>
            </b-col>             
          </b-row>

          <b-row>
            <b-col>
              <div class="text-right mt-2 mr-2 mb-2">
                <b-button
                  v-if="restrictions('button_edit_user')"
                  :to="{ name: 'apps-users-edit', params: { id: userData.id } }"
                  variant="primary"
                >
                  {{ $t("message.buttons.edit") }}
                </b-button>
                <b-button
                  v-if="restrictions('button_delete_user')"
                  v-b-modal.modal-delete-confirmation
                  variant="outline-danger"
                  class="ml-1"
                >
                  {{ $t("message.buttons.delete") }}
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </template>
    <template>
      <b-modal
        id="modal-delete-confirmation"
        modal-class="modal-danger"
        centered
        :title="$t('delete')"
        hide-footer
      >
        <b-card-text>
          {{ $t("message.deletAcount") }}
        </b-card-text>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
          <b-button
            v-b-modal.modal-danger
            class="mt-2"
            variant="danger"
            :to="{ name: 'apps-users-list' }"
            @click="deleteUser()"
          >
            {{ $t("message.buttons.delete") }}
          </b-button>
        </div>
      </b-modal>
    </template>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BButton,
  BCardText,
  BCardGroup,
  BCard,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AccessControl from "@core/utils/access-control";
import store from "@/store";

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BButton,
    BCardText,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormCheckbox,
  },
  setup() {
    const userData = ref(null);

    axios({
      method: "get",
      url: `${URL_API}user/${router.currentRoute.params.id}`,
      headers: getHeader(store.state.user.userData),
    })
      .then((response) => {
        userData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          userData.value = undefined;
        }
      });

    return {
      userData,
      vSelect,
    };
  },

  data() {
    return {
      //Select
      groupOptions: [],

      typeOptions: [
        { label: "Administrador", value: "ROLE_ADMIN" },
        { label: "Usuário", value: "ROLE_USER" },
      ],

      userDataObj: store.state.user.userData,
    };
  },

  async created() {
    var groupList = [];
    await axios
      .get(`${URL_API}userGroup`, { headers: getHeader(this.userDataObj) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const description = response.data[i].description;
          const accessControlId = response.data[i].accessControlId;
          groupList.push(description);
          this.groupOptions.push({
            label: description,
            value: accessControlId,
          });
        }
      });
  },

  methods: {
    deleteUser() {
      axios({
        method: "delete",
        url: `${URL_API}user/${this.userData.id}`,
        headers: getHeader(this.userDataObj),
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("message.toast.deleted"),
              icon: "CheckIcon",
              variant: "success",
              text: `O usuário foi deletada com sucesso`,
            },
          });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            userData.value = undefined;
          }
        });
    },

    restrictions(value) {
      return AccessControl(value);
    },
  },
};
</script>

<style></style>
